<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row >
            <v-col style="margin-bottom:1%">
                <h1>{{$store.getters.getTextMap().log_details}}
</h1>
            </v-col>
            <v-col align="right">
                    <v-btn small outlined v-if="!loading"  :color="$store.getters.getColorPalette().accentCode" @click="goBack">
                        <v-icon :color="$store.getters.getColorPalette().accentCode">mdi-close</v-icon>
                    </v-btn>
                </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color=" $store.getters.getColorPalette().background2ColorCode">
        <v-row style="margin-left:20px;">
            <v-col>
                <v-checkbox
                    :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().inputtextColor"
                    v-model="dailyData"
                    :label="$store.getters.getTextMap().daily_data"
                ></v-checkbox>
            </v-col>
            <v-col>
                <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :dark="$store.getters.getColorPalette().isDark"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="fromTime"
                            :label="$store.getters.getTextMap().from_date"
                            prepend-icon="mdi-calendar"
                            readonly
                            :loading="loading"
                            v-bind="attrs"
                            v-on="on"
                            :rules="ISODateRules"
                            :dark="$store.getters.getColorPalette().isDark"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="fromTime"
                        @input="menu1 = false"
                        :dark="$store.getters.getColorPalette().isDark"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :dark="$store.getters.getColorPalette().isDark"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="toTime"
                            :label="$store.getters.getTextMap().to_date"
                            prepend-icon="mdi-calendar"
                            readonly
                            :loading="loading"
                            v-bind="attrs"
                            v-on="on"
                            :rules="ISODateRules"
                            :dark="$store.getters.getColorPalette().isDark"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="toTime"
                        @input="menu2 = false"
                        :dark="$store.getters.getColorPalette().isDark"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col style="margin-top:2%">
                <v-btn  class="mx-2" small outlined v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" @click="getData()">
                    {{$store.getters.getTextMap().view}}

                </v-btn>
            </v-col>
        </v-row>
        <v-card flat :dark="$store.getters.getColorPalette().isDark" >
            <v-data-table
                :headers="headers"
                :items="tableData"
                item-key="timestamp"
                :loading="loading"
                :dark="$store.getters.getColorPalette().isDark"
                loading-text="Loading... Please wait"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!-- <v-toolbar-title>Log Details</v-toolbar-title> -->
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-icon @click="getData">mdi-refresh</v-icon>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card>
    </v-card>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import moment from 'moment'

export default {
    name:'DataLogDetailsable',
    components:{
        InfoAlert
    },
    mounted(){
        this.fromTime=moment().subtract(180,'d').format('YYYY-MM-DD')
        this.toTime=moment().format('YYYY-MM-DD')
        this.log_id=this.$route.params.id
        this.fetchDataLoggerListDetails()
    },
    data(){
            return {
                info:'',
                paramData:[],
                showDismissibleAlert:false,
                loading:false,
                expanded: [],
                form:{
                    date:null,
                    dataLog:[],
                },
                dailyData:true,
                apiEndpoint:'getLoggedParamDailyDataDetails',
                menu:false,
                menu1:false,
                menu2:false,
                menu3:false,
                toTime:null,
                fromTime:null,
                log_id:null,
                tableData:[],
                objectRules:[
                        v => !!v || 'Required',
                ],
                ISODateRules:[
                    v => !!v || 'Required',
                    v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
                ],
                headers:[
                    {text: 'Date', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                ],
                dataFetched: false,
            }
    },
    computed:{
        fromTimeStamp(){
            if(this.fromTime){
                let dt=this.fromTime.toString()
                return Number(moment(dt,'YYYY-MM-DD').format("X"));
            }else{
            return null
            }
        },
        toTimeStamp(){
            if(this.toTime){
                let dt=this.toTime.toString()
                return Number(moment(dt,'YYYY-MM-DD').add('days',1).format("X"));
            }else{
                return null
            }
        },
    },
    methods:{
        goBack(){
            this.$router.push("/logbook");
        },
        getParamTableData(){
            this.tableData=[]
            let temp=null
            if(!this.dailyData){
                temp=this.paramData.reduce((prev,current)=>{
                    if(!(current.timestamp in prev)){
                        prev[current.timestamp]={
                            timestamp:current.timestamp
                        }
                    }
                    prev[current.timestamp][current.param_id]=current['data']
                    return prev
                },{})
            }else{
                temp=this.paramData.reduce((prev,current)=>{
                    if(!(current.date in prev)){
                        prev[current.date]={
                            timestamp:current.date
                        }
                    }
                    prev[current.date][current.param_id]=current['usage']
                    return prev
                },{})
            }
            this.tableData=Object.values(temp)
            if(this.dataLog && this.dataLog.length>0){
                for(let h of this.dataLog){
                    this.headers.push({text:h['label'], value:h['param_id'],class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
                }
            }
        },
        getData(){
         if (this.dataFetched) {
            this.headers=[
                    {text: 'Date', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
                ]
            }
            this.loading = true;
            let payload={
                log_id:this.log_id,
            }
            this.dailyData?payload['from_date']=this.fromTime.toString():payload['from_timestamp']=this.fromTimeStamp
            this.dailyData?payload['to_date']=this.toTime.toString():payload['to_timestamp']=this.toTimeStamp
            this.paramData = []
            axios.post(this.$store.state.api + this.apiEndpoint,payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
            if (response.data.status == "success") {
                    this.paramData=response.data.data 
                    if(!this.dailyData){
                        this.paramData = this.paramData.map(x => {
                            x.timestamp = moment.unix(x.timestamp).format('YYYY-MM-DD HH:mm:ss')
                            return x
                        })
                    }
                    
                    this.getParamTableData()
                     this.dataFetched = true;
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
            this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        fetchDataLoggerListDetails(){
        let payload = {
            log_id:this.log_id
        }
        axios.post(this.$store.state.api+"getDataLoggerList",payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
        .then(response=>{
            if(response.data.status==='success'){
                this.dataLog = response.data.data
                // this.dataLog.paramDetailsList = this.paramDetailsList
            }else{
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            }
            this.loading=false
        }).catch(err=>{
            console.log(err)
            this.showDismissibleAlert=true
            this.info=err
            this.loading=false
        })
    },
    },
    watch:{
        dailyData:{
            handler(){
                if(this.dailyData){
                    this.apiEndpoint='getLoggedParamDailyDataDetails'
                }else{
                    this.apiEndpoint='getLoggedParamDetails'
                }
            },
        deep:true
        }
    }
}
</script>